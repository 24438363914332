<template>
    <div>
        <div class="row">
            <div class="offset-4 col-2 offset-lg-5 col-lg-2">
                <div id="loader" class="loader mb-3" v-if="loader"></div>
            </div>
        </div>

        <div v-if="!loader">
            <div class="alert alert-success" v-if="submitted">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <strong>
                    <i class="fa fa-check-circle fa-lg fa-fw"></i>Success! &nbsp;
                </strong>
                {{ response_message }}
            </div>

            <div class="alert alert-danger" v-if="failed">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <strong>Whoops!!</strong>
                {{ response_message }}
            </div>

            <form id="so-create-form" method="POST" enctype="multipart/form-data" v-on:submit.prevent="submitCreateRequest">
                <fieldset v-bind:disabled="submitted">
                    <input type="hidden" name="_token" v-bind:value="this.csrf">

                    <div class="row mb-3">
                        <div class="col-lg-6 mb-3 mb-lg-0">
                            <div class="card">
                                <div class="card-header bg-info" style="color: #ffffff;"><strong>SO Details</strong></div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <label for="so_number" class="col-lg-3 text-lg-right">SO Number: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <input id="so_number" type="text" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.so_number !== 'undefined'}"
                                                   name="so_number" v-bind:value="this.so_number" required readonly>

                                            <span class="invalid-feedback" v-if="this.errors.so_number">
                                                <strong>{{ this.errors.so_number[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="this.default_customer">
                                        <input type="hidden" name="default_customer" v-bind:value="this.default_customer">
                                    </div>

                                    <div class="form-group row">
                                        <label for="customer_id" class="col-lg-3 text-lg-right">Customer: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="customer_id" v-model="customer_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.customer_id !== 'undefined'}"
                                                    name="customer_id" v-on:change="loadLocationData">
                                                <option value="" disabled>Select Customer</option>
                                                <option v-for="customer in this.customer_options" v-bind:value="customer.id">{{ customer.name }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.customer_id">
                                                <strong>{{ this.errors.customer_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="warehouse_id" class="col-lg-3 text-lg-right">Warehouse: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="warehouse_id" v-model="warehouse_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.warehouse_id !== 'undefined'}"
                                                    name="warehouse_id" v-on:change="loadLocationData">
                                                <option value="" disabled>Select Warehouse</option>
                                                <option v-for="warehouse in this.warehouse_options" v-bind:value="warehouse.id">{{ warehouse.name }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.warehouse_id">
                                                <strong>{{ this.errors.warehouse_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="customer_location_id" class="col-lg-3 text-lg-right">Location: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="customer_location_id" v-model="customer_location_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.customer_location_id !== 'undefined'}"
                                                    name="customer_location_id">
                                                <option value="" disabled>Select Customer Location</option>
                                                <option v-for="customer_location in this.customer_location_options" v-bind:value="customer_location.id">{{ customer_location.name }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.customer_location_id">
                                                <strong>{{ this.errors.customer_location_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="comment" class="col-lg-3 text-lg-right">Comment:</label>
                                        <div class="col-lg-9">
                                            <textarea id="comment" v-model="comment" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.comment !== 'undefined'}"
                                                      name="comment" placeholder="Need to add a note to the SO?" rows="5"></textarea>

                                            <span class="invalid-feedback" v-if="this.errors.comment">
                                                <strong>{{ this.errors.comment[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="user_id" class="col-lg-3 text-lg-right">Created By: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="user_id" v-model="user_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.user_id !== 'undefined'}"
                                                    name="user_id" readonly>
                                                <option v-bind:value="this.user['id']" selected="selected">{{ this.user['name'] }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.user_id">
                                                <strong>{{ this.errors.user_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <div class="card">
                                    <div class="card-header bg-info" style="color: #ffffff;"><strong>Add Line Items</strong></div>
                                    <div class="card-body">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6 mb-2">
                            <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-usd"></i> Create Sales Order</button>
                        </div>
                        <div class="col-lg-6">
                            <button type="reset" class="btn btn-secondary btn-block"><i class="fa fa-refresh"></i> Reset</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
    import VueScrollTo from 'vue-scrollto';

    export default {
        name: "SalesOrderCreate",
        props: [
            'action', 'csrf', 'so_number', 'default_customer', 'customers', 'warehouses', 'user', 'token',
        ],
        data () {
            return {
                loader: false,
                submitted: false,
                failed: false,
                response_message: '',
                errors: [],

                customer_options: this.customers,
                customer_id: '',
                warehouse_options: this.warehouses,
                warehouse_id: '',
                warehouse_location_options: [],
                warehouse_location_id: '',
                customer_location_options: [],
                customer_location_id: '',
                comment: '',
                user_id: this.user['id'],

                formData: new FormData(),
            }
        },
        watch: {
        },
        mounted () {
            if(this.default_customer) {
                this.customer_id = this.default_customer;
            }
        },
        methods: {
            loadLocationData() {

                // Reset Data
                this.customer_location_id = '';
                this.item = '';
                this.item_qty = '';
                this.items = [];

                // Verify both customer and warehouse were set
                if (this.customer_id && this.warehouse_id) {

                    // Set locations URL -- Populate Data
                    let url = '/api/warehouse-locations?warehouse_id=' + this.warehouse_id;
                    this.populateData(url, 'warehouse_location_options');

                    // Set locations URL -- Populate Data
                    url = '/api/customer-locations?customer_id=' + this.customer_id + '&warehouse_id=' + this.warehouse_id;
                    this.populateData(url, 'customer_location_options');
                }
            },
            populateData(url, data) {
                axios.get(url, { headers: {"Authorization" : "Bearer " + this.token}})
                    .then(response => this[data] = response.data)
                    .catch(error => console.log(error))
            },
            prepareFormData() {

                // Set the submission data
                this.formData.append('so_number', this.so_number);
                this.formData.append('customer_id', this.customer_id);
                this.formData.append('warehouse_id', this.warehouse_id);
                this.formData.append('customer_location_id', this.customer_location_id);
                this.formData.append('comment', this.comment);
                this.formData.append('user_id', this.user_id);

            },
            submitCreateRequest() {

                // Set the loader to show submitting
                this.loader = true;
                VueScrollTo.scrollTo('#so-create-form', 1000, { easing: 'ease-in', offset: -350 } );

                // Set the form data
                this.prepareFormData();

                // Setup the POST config
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + this.token }
                };

                // Send the Post request
                axios.post(this.action, this.formData, config).then((response) => {

                    // Turn off the loader
                    this.loader = false;

                    // Refresh form data
                    Object.assign(this.$data, this.$options.data.call(this));

                    // Set the response
                    this.submitted = true;
                    this.response_message = "Thank you for your request.  Your sales order is now in process.";

                    // Check if default customer was set
                    if (this.default_customer) {

                        // Redirect with customer flag
                        window.location.href = "/dashboard/inventory-management/sales-orders/" + response.data.id + "?customer_id=" + this.default_customer;

                    } else {

                        // Redirect
                        window.location.href = "/dashboard/inventory-management/sales-orders/" + response.data.id;
                    }

                }).catch((error) => {

                    // Turn off the loader
                    this.loader = false;

                    // Reset the form data
                    this.formData = new FormData();

                    // Handle the errors
                    if (error.response.status) {

                        // Show request validation errors
                        this.failed = true;
                        this.response_message = error.response.data.message + " Please check any feedback, if provided, below for more details.";
                        this.errors = (error.response.data.errors) ? error.response.data.errors : null;
                    }
                });
            },
        },
        components: { VueScrollTo }
    }
</script>

<style scoped>
    .loader {
        transform: translate(-50%, -50%);
        border: 15px solid #f3f3f3; /* Light grey */
        border-top: 13px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
