<template>
    <div>
        <div class="row">
            <div class="offset-4 col-2 offset-lg-5 col-lg-2">
                <div id="loader" class="loader mb-3" v-if="loader"></div>
            </div>
        </div>

        <div v-if="!loader">
            <div class="alert alert-success" v-if="submitted">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <strong>
                    <i class="fa fa-check-circle fa-lg fa-fw"></i>Success! &nbsp;
                </strong>
                {{ response_message }}
            </div>

            <div class="alert alert-danger" v-if="failed">
                <button type="button" class="close" data-dismiss="alert">&times;</button>
                <strong>Whoops!!</strong>
                {{ response_message }}
            </div>

            <form id="po-create-form" method="POST" enctype="multipart/form-data" v-on:submit.prevent="submitCreateRequest">
                <fieldset v-bind:disabled="submitted">
                    <input type="hidden" name="_token" v-bind:value="this.csrf">

                    <div class="row mb-3">
                        <div class="col-lg-6 mb-3 mb-lg-0">
                            <div class="card">
                                <div class="card-header bg-info" style="color: #ffffff;"><strong>PO Details</strong></div>
                                <div class="card-body">
                                    <div class="form-group row">
                                        <label for="po_number" class="col-lg-3 text-lg-right">PO Number: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <input id="po_number" type="text" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.po_number !== 'undefined'}"
                                                   name="po_number" v-bind:value="this.po_number" required readonly>

                                            <span class="invalid-feedback" v-if="this.errors.po_number">
                                                <strong>{{ this.errors.po_number[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div v-if="this.default_supplier">
                                        <input type="hidden" name="default_supplier" v-bind:value="this.default_supplier">
                                    </div>

                                    <div class="form-group row">
                                        <label for="supplier_id" class="col-lg-3 text-lg-right">Supplier: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="supplier_id" v-model="supplier_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.supplier_id !== 'undefined'}"
                                                    name="supplier_id" v-on:change="loadDataBySupplier">
                                                <option value="" disabled>Select Supplier</option>
                                                <option v-for="supplier in this.supplier_options" v-bind:value="supplier.id">{{ supplier.name }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.supplier_id">
                                                <strong>{{ this.errors.supplier_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="warehouse_id" class="col-lg-3 text-lg-right">Warehouse: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="warehouse_id" v-model="warehouse_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.warehouse_id !== 'undefined'}"
                                                    name="warehouse_id">
                                                <option value="" disabled>Select Warehouse</option>
                                                <option v-for="warehouse in this.warehouse_options" v-bind:value="warehouse.id">{{ warehouse.name }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.warehouse_id">
                                                <strong>{{ this.errors.warehouse_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="comment" class="col-lg-3 text-lg-right">Comment:</label>
                                        <div class="col-lg-9">
                                            <textarea id="comment" v-model="comment" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.comment !== 'undefined'}"
                                                      name="comment" placeholder="Need to add a note to the PO?" rows="5"></textarea>

                                            <span class="invalid-feedback" v-if="this.errors.comment">
                                                <strong>{{ this.errors.comment[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="user_id" class="col-lg-3 text-lg-right">Created By: <span class="text-danger">*</span></label>

                                        <div class="col-lg-9">
                                            <select id="user_id" v-model="user_id" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.user_id !== 'undefined'}"
                                                    name="user_id" readonly>
                                                <option v-bind:value="this.user['id']" selected="selected">{{ this.user['name'] }}</option>
                                            </select>

                                            <span class="invalid-feedback" v-if="this.errors.user_id">
                                                <strong>{{ this.errors.user_id[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group">
                                <div class="card">
                                    <div class="card-header bg-info" style="color: #ffffff;"><strong>Add Charges</strong></div>
                                    <div class="card-body">
                                        <div class="row" v-if="charges.length">
                                            <div class="col-lg-12">
                                                <ul class="list-group">
                                                    <li class="list-group-item" v-for="charge in this.charges">
                                                        - <strong>{{ charge.name }} for ${{ charge.cost }}</strong>

                                                        <button type="button" v-on:click="removeCharge(charge)" class="btn btn-sm btn-danger pull-right">
                                                            <i class="fa fa-times-circle"></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-lg-12">
                                                <select id="charge" v-model="charge" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.charges !== 'undefined'}"
                                                        name="charge">
                                                    <option value="" disabled>Select Charge Type</option>
                                                    <option v-for="charge in this.charge_types" v-bind:value="charge">{{ charge.name }}</option>
                                                </select>

                                                <span class="invalid-feedback" v-if="this.errors.charges">
                                                    <strong>{{ this.errors.charges[0] }}</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3 mb-lg-0">
                                                <div class="input-group">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"><i class="fa fa-dollar"></i></span>
                                                    </div>

                                                    <input id="charge_cost" v-model="charge_cost" type="number" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.charge_cost !== 'undefined'}"
                                                           name="charge_cost" placeholder="99.99" min="0" step="0.01" data-number-to-fixed="2" data-number-stepfactor="100">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <button type="button" class="btn btn-block btn-outline-info" v-on:click="addCharge">Add Charge</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="card">
                                    <div class="card-header bg-info" style="color: #ffffff;"><strong>Add Line Items</strong></div>
                                    <div class="card-body">
                                        <div class="row" v-if="items.length">
                                            <div class="col-lg-12">
                                                <ul class="list-group">
                                                    <li class="list-group-item" v-for="item in this.items">
                                                        <strong>{{ item.name }} - {{ item.qty }}</strong>

                                                        <button type="button" v-on:click="removeItem(item)" class="btn btn-sm btn-danger pull-right">
                                                            <i class="fa fa-times-circle"></i>
                                                        </button>
                                                    </li>
                                                </ul>
                                                <hr />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-lg-12">
                                                <select id="material" v-model="material" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.items !== 'undefined'}"
                                                        name="material">
                                                    <option value="" disabled>Select Material</option>
                                                    <option v-for="material in this.material_options" v-bind:value="material">{{ material.part_number }} - {{ material.description }}</option>
                                                </select>

                                                <span class="invalid-feedback" v-if="this.errors.items">
                                                    <strong>{{ this.errors.items[0] }}</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3 mb-lg-0">
                                                <input id="material_qty" v-model="material_qty" type="number" v-bind:class="{ 'form-control':true, 'is-invalid': typeof this.errors.material_qty !== 'undefined'}"
                                                       name="material_qty" placeholder="1" min="1">
                                            </div>
                                            <div class="col-lg-6">
                                                <button type="button" class="btn btn-block btn-outline-info" v-on:click="addItem">Add Line Item</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6 mb-2">
                            <button type="submit" class="btn btn-primary btn-block"><i class="fa fa-shopping-cart"></i> Create Purchase Order</button>
                        </div>
                        <div class="col-lg-6">
                            <button type="reset" class="btn btn-secondary btn-block"><i class="fa fa-refresh"></i> Reset</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
    import VueScrollTo from 'vue-scrollto';

    export default {
        name: "PurchaseOrderCreate",
        props: [
            'action', 'csrf', 'po_number', 'default_supplier', 'suppliers', 'warehouses', 'user', 'materials', 'charge_types', 'token',
        ],
        data () {
            return {
                loader: false,
                submitted: false,
                failed: false,
                response_message: '',
                errors: [],

                supplier_raw: '',
                supplier_options: this.suppliers,
                warehouse_options: this.warehouses,
                charge: '',
                charge_cost: '',
                material_options: this.materials,
                material: '',
                material_qty: '',

                supplier_id: '',
                warehouse_id: '',
                comment: '',
                user_id: this.user['id'],
                charges: [],
                items: [],

                formData: new FormData(),
            }
        },
        watch: {
            supplier_raw: function() {
                this.warehouse_options = this.supplier_raw['warehouses'];
                this.material_options = this.supplier_raw['materials'];
            }
        },
        mounted () {
            if(this.default_supplier) {
                this.supplier_id = this.default_supplier;
            }
        },
        methods: {
            loadDataBySupplier() {

                // Reset Data
                this.warehouse_id = '';

                this.charge = '';
                this.charge_cost = '';
                this.charges = [];

                // Set supplier URL -- Populate Data
                let url = `/api/suppliers/${this.supplier_id}`;
                this.populateData(url, 'supplier_raw');
            },
            populateData(url, data) {
                axios.get(url, { headers: {"Authorization" : "Bearer " + this.token}})
                    .then(response => this[data] = response.data)
                    .catch(error => console.log(error))
            },
            addCharge() {

                // Verify values are set
                if (this.charge && this.charge_cost && (this.charge_cost > 0)) {

                    // Determine if value was already used
                    let hasCharge = this.charges.some( value => value['id'] === this.charge.id );

                    // Verify
                    if (!hasCharge) {

                        let obj = {'id': this.charge.id, 'name': this.charge.name, 'cost': this.charge_cost};
                        this.charges.push(obj);

                        this.charge = '';
                        this.charge_cost = '';
                    }
                }
            },
            removeCharge(charge) {
                this.charges.splice(this.charges.indexOf(charge), 1);
            },
            addItem() {

                // Verify values are set
                if (this.material && this.material_qty && (this.material_qty > 0)) {

                    // Determine if value was already used
                    let hasItem = this.items.some( value => value['id'] === this.material.id );

                    // Verify
                    if (!hasItem) {

                        let obj = {'id': this.material.id, 'name': '(' + this.material.part_number + ') ' + this.material.description, 'qty': this.material_qty, 'purchase_price': this.material.cost};
                        this.items.push(obj);

                        this.material = '';
                        this.material_qty = '';
                    }
                }
            },
            removeItem(item) {
                this.items.splice(this.items.indexOf(item), 1);
            },
            prepareFormData() {

                // Set the submission data
                this.formData.append('po_number', this.po_number);
                this.formData.append('supplier_id', this.supplier_id);
                this.formData.append('warehouse_id', this.warehouse_id);
                this.formData.append('comment', this.comment);
                this.formData.append('user_id', this.user_id);

                // Set the charges
                if (this.charges.length > 0) {
                    for (let i = 0; i < this.charges.length; i++) {
                        let charge = this.charges[i];
                        this.formData.append('charges[]', JSON.stringify(charge));
                    }
                }

                // Set the items
                if (this.items.length > 0) {
                    for (let i = 0; i < this.items.length; i++) {
                        let item = this.items[i];
                        this.formData.append('items[]', JSON.stringify(item));
                    }
                }
            },
            submitCreateRequest() {

                // Set the loader to show submitting
                this.loader = true;
                VueScrollTo.scrollTo('#po-create-form', 1000, { easing: 'ease-in', offset: -350 } );

                // Set the form data
                this.prepareFormData();

                // Setup the POST config
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + this.token }
                };

                // Send the Post request
                axios.post(this.action, this.formData, config).then((response) => {

                    // Turn off the loader
                    this.loader = false;

                    // Refresh form data
                    Object.assign(this.$data, this.$options.data.call(this));

                    // Set the response
                    this.submitted = true;
                    this.response_message = "Thank you for your request.  Your purchase order is now in process.";

                    // Check if default supplier was set
                    if (this.default_supplier) {

                        // Redirect with supplier flag
                        window.location.href = "/dashboard/inventory-management/purchase-orders/" + response.data.id + "?supplier_id=" + this.default_supplier;

                    } else {

                        // Redirect
                        window.location.href = "/dashboard/inventory-management/purchase-orders/" + response.data.id;
                    }

                }).catch((error) => {

                    // Turn off the loader
                    this.loader = false;

                    // Reset the form data
                    this.formData = new FormData();

                    // Handle the errors
                    if (error.response.status) {

                        // Show request validation errors
                        this.failed = true;
                        this.response_message = error.response.data.message + " Please check any feedback, if provided, below for more details.";
                        this.errors = (error.response.data.errors) ? error.response.data.errors : null;
                    }
                });
            },
        },
        components: { VueScrollTo }
    }
</script>

<style scoped>
    .loader {
        transform: translate(-50%, -50%);
        border: 15px solid #f3f3f3; /* Light grey */
        border-top: 13px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
